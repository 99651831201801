<template>
  <div class="box">
    <base-button
      style="background-color: #5aa6f9"
      :http-request="UploadFile"
      :btnType="'upload'"
      label="上传"
      accept=".doc,.docx,.xlsx,.xls,.pdf,.png,.jpg,.jpeg,.gif,.bmp"
    ></base-button>
    <div style="margin-left: 20px">
      <el-tooltip
        class="item"
        effect="dark"
        :content="fileName"
        placement="top"
        v-if="fileName"
      >
        <p class="tool" v-bind="$attrs" v-on="$listeners" @click="downloadFile(fileId)">{{ fileName }}</p>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { fileAPi, downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'
export default {
  name: 'UploadFile',
  data () {
    return {
      fileName: '', // 文件名
      fileId: '' // 文件id
    }
  },
  props: {
    // 清除文件名
    fileShow: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    fileShow (newval, oldval) {
      if (!newval) {
        this.fileName = ''
      }
    }
  },
  components: { BaseButton },
  methods: {
    /***
     * 文件上传
     * @param param
     * @constructor
     */
    UploadFile (param) {
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (EmojiReg.test(param.file.name)) {
        this.warning('文件名不能存在Emoji')
        return false
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize >= 0) {
        return this.warning('文件需小于10M')
      }
      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      fileAPi.file(formData).then((res) => {
        if (res.success) {
          if (res.data) {
            console.log(res.data, '11')
            this.fileName = res.data.fileName
            this.fileId = res.data.keyId
            this.$emit('uploadFile', res.data)
          }
        }
      })
    },
    // 文件下载
    downloadFile (fileId) {
      if (fileId) {
        const params = { keyId: fileId }
        downAPi.download(params).then(res => {
          downFile(res)
        }).catch(e => {
          this.error(e)
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
}

.tool {
  width: 100px;
  height: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
